import React from 'react'
import PropTypes from 'prop-types'

import {Large} from 'components/common/Responsive'

import classes from './DeviceModelSummary.module.css'
import DeviceModelSummary from './DeviceModelSummary'

const DeviceModelSummaryList = ({models}) => (
  <Large>
    {matches => (
      <div style={matches ? {justifyContent: 'space-between'} : {justifyContent: 'space-around'}} className={classes.deviceModelList}>
        {
          models.map((m, i) => (
            <DeviceModelSummary key={i} data={m} />
          ))
        }
      </div>
    )}
  </Large>
)

DeviceModelSummaryList.propTypes = {
  models: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default DeviceModelSummaryList
