import React from 'react'
import PropTypes from 'prop-types'

import Image from 'gatsby-image'
import {Row, Col} from 'react-flexbox-grid'
import {withNamespaces} from 'react-i18next'

import Link from 'components/common/Link'
import {Small} from 'components/common/Responsive'

import classes from './DeviceModel.module.css'

const DeviceModel = ({t, name, image, categories, otherModels, manufacturer}) => (
  <Row>
    <Col md={6} lg={7}>
      <Header name={name} image={image} />
      <ParameterCategories categories={categories} />
    </Col>
    <Col md={5} lg={4} mdOffset={1} lgOffset={1}>
      <DeviceModelList
        t={t}
        otherModels={otherModels}
        manufacturer={manufacturer}
      />
    </Col>
  </Row>
)

DeviceModel.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  otherModels: PropTypes.array.isRequired,
  manufacturer: PropTypes.string.isRequired
}

const Header = ({name, image}) => (
  <Small>
    {matches => (
      <div style={matches ? {textAlign: 'left'} : {textAlign: 'center'}}>
        <h1 style={{margin: '45px 0 15px', fontWeight: 'bold', fontSize: 50}}>{name}</h1>
        <Image fixed={image} style={{maxHeight: 360, marginBottom: 30}} />
      </div>
    )}
  </Small>
)

Header.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired
}

const ParameterCategory = ({category}) => (
  <div>
    <h3 style={{textAlign: 'left', margin: '40px 0 0 0'}}>
      {category.name}
    </h3>
    <table>
      <tbody>
        {
          category.properties.map((p, i) => (
            <tr key={i}>
              <td style={{width: '50%', color: 'gray'}}>{p.name}</td>
              <td>{p.value}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
)

ParameterCategory.propTypes = {
  category: PropTypes.object.isRequired
}

const ParameterCategories = ({categories}) => (
  <div>
    {
      categories.map((c, i) => (
        <ParameterCategory key={i} category={c} />
      ))
    }
  </div>
)

ParameterCategories.propTypes = {
  categories: PropTypes.array.isRequired
}

const DeviceModelList = ({t, otherModels, manufacturer}) => (
  <Small>
    {matches => (
      <div>
        <h3 style={{margin: '45px 0 15px'}}>
          {`${t('fleerp.DeviceModel.moreFrom')} ${manufacturer}`}
        </h3>
        <div style={matches ? {height: 650, overflow: 'auto', borderLeft: '1px solid #dcdcdc'} : null}>
          {
            otherModels.map((m, i) => (
              <DeviceModelListItem key={i} model={m} />
            ))
          }
        </div>
      </div>
    )}
  </Small>
)

DeviceModelList.propTypes = {
  t: PropTypes.func.isRequired,
  otherModels: PropTypes.array.isRequired,
  manufacturer: PropTypes.string.isRequired
}

const DeviceModelListItem = ({model}) => (
  <Link to={model.href} className={classes.listItem} activeClassName={classes.activeListItem}>
    <Image
      fixed={model.image}
      style={{maxHeight: 50, width: 80, float: 'left'}}
      imgStyle={{width: 'auto', position: 'relative'}}
    />
    <h4 style={{paddingTop: 12}}>{model.name}</h4>
  </Link>
)

DeviceModelListItem.propTypes = {
  model: PropTypes.object.isRequired
}

export default withNamespaces()(DeviceModel)
