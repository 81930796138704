import React from 'react'
import PropTypes from 'prop-types'

import Image from 'gatsby-image'

import Link from 'components/common/Link'

import classes from './DeviceModelSummary.module.css'

const DeviceModelSummary = ({data}) => (
  <div className={classes.deviceModel}>
    <DeviceModelSummaryImage src={data.image} href={data.href} />
    <DeviceModelSummaryContent name={data.name} href={data.href} summary={data.summary} />
  </div>
)

DeviceModelSummary.propTypes = {
  data: PropTypes.object.isRequired
}

const DeviceModelSummaryImage = ({src, href}) => (
  <Link to={href} className={classes.deviceModelImage}>
    <Image
      fixed={src}
      style={{maxHeight: 160}}
      imgStyle={{width: 'auto', position: 'relative'}}
    />
  </Link>
)

DeviceModelSummaryImage.propTypes = {
  src: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired
}

const DeviceModelSummaryContent = ({name, href, summary}) => (
  <div>
    <Link to={href}>
      <h4>{name}</h4>
    </Link>
    <div className={classes.deviceModelDescription}>
      {summary}
    </div>
  </div>
)

DeviceModelSummaryContent.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired
}

export default DeviceModelSummary
