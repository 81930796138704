import React from 'react'
import PropTypes from 'prop-types'

import {withNamespaces} from 'react-i18next'
import {Grid, Row, Col} from 'react-flexbox-grid'

import Link from 'components/common/Link'

import classes from './DeviceModelSummaryListHeader.module.css'

const DeviceModelSummaryListHeader = ({t}) => (
  <Grid style={{margin: '40px 0'}}>
    <Row middle='xs' start='md' center='xs' between='xs'>
      <Col xs={12} md={4}>
        <h2>{t('fleerp.DeviceModel.DeviceModelSummaryListHeader.title')}</h2>
      </Col>

      <Col xs={12} md={6} mdOffset={2} style={{marginBottom: 10}}>
        <div className={classes.contactTitle}>
          {`${t('fleerp.DeviceModel.DeviceModelSummaryListHeader.contactTitle')}`}
        </div>
        <Link to='/contact#form' className={classes.contactButton}>
          {`${t('fleerp.DeviceModel.DeviceModelSummaryListHeader.contactButton')}`}
        </Link>
      </Col>
    </Row>
  </Grid>
)

DeviceModelSummaryListHeader.propTypes = {
  t: PropTypes.func.isRequired
}

export default withNamespaces()(DeviceModelSummaryListHeader)
