import React from 'react'
import PropTypes from 'prop-types'

import {graphql} from 'gatsby'
import Helmet from 'react-helmet'
import {withNamespaces} from 'react-i18next'

import Layout from 'components/Layout'
import {DeviceModel} from 'components/fleerp/DeviceModel'

export const DeviceModelTemplate = ({t, data, navigate, location}) => {
  const otherModels = []

  data.jsonFiles.edges.map(({node}) => {
    otherModels.push({
      href: `/${node.relativeDirectory.replace('models', node.name)}`,
      ...node.childModelsJson
    })
  })

  data.imageFiles.edges.map(({node}, i) => {
    otherModels[i].image = node.childImageSharp.fixed
  })

  return (
    <Layout breadcrumb navigate={navigate} location={location}>
      <Helmet
        title={`${data.jsonFile.childModelsJson.name} - ${t('fleerp.DeviceModelTemplate.meta.title')}`}
        meta={[{name: 'keywords', content: data.jsonFile.childModelsJson.name}]}
      />
      <DeviceModel
        name={data.jsonFile.childModelsJson.name}
        image={data.imageFile.childImageSharp.fixed}
        categories={data.jsonFile.childModelsJson.categories}
        manufacturer={data.jsonFile.childModelsJson.manufacturer}
        otherModels={otherModels}
      />
    </Layout>
  )
}

DeviceModelTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export const query = graphql`
  query($slug: String!, $jsonSlug: String!, $imageSlug: String!) {
    jsonFile: file(relativePath: {eq: $jsonSlug}) {
      childModelsJson {
        name
        manufacturer
        categories {
          name
          properties {
            name
            value
          }
        }
      }
    }
    imageFile: file(relativePath: {eq: $imageSlug}) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      } 
    }
    jsonFiles: allFile(filter: {relativeDirectory: {eq: $slug}, extension: {eq: "json"}}, sort: {fields: name}) {
      edges {
        node {
          name
          relativeDirectory
          childModelsJson {
            name
            summary
          }
        }
      }
    }
    imageFiles: allFile(filter: {relativeDirectory: {eq: $slug}, extension: {eq: "png"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }
`

export default withNamespaces()(DeviceModelTemplate)
