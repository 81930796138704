import DeviceModel from './DeviceModel'
import DeviceModelSummary from './DeviceModelSummary'
import DeviceModelSummaryList from './DeviceModelSummaryList'
import DeviceModelSummaryListHeader from './DeviceModelSummaryListHeader'

export {
  DeviceModel,
  DeviceModelSummary,
  DeviceModelSummaryList,
  DeviceModelSummaryListHeader
}

export default DeviceModel
